@font-face { font-family: "SFPro"; font-weight: 400; font-style: normal; src: url("../../assets/fonts/SFProText-Regular.ttf"); }
@font-face { font-family: "SFPro"; font-weight: 500; font-style: normal; src: url("../../assets/fonts/SFProText-Medium.ttf"); }
@font-face { font-family: "SFProD"; font-weight: 400; font-style: normal; src: url("../../assets/fonts/SFProDisplay-Regular.ttf"); }
@font-face { font-family: "TTBold"; font-weight: 600; font-style: normal; src: url("../../assets/fonts/TTCommons-Bold.ttf"); }


* {
  box-sizing: border-box;
}

.app {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.8);

  &.modal-dark {
    .ModalRoot__mask {
      background-color: rgba(0,0,0,0) !important;
    }
  }

  &__wrapper {
    position: absolute;
    top: 23px;
    left: 50%;

    transform: translateX(-50%);

    width: 660px;
    margin: 0 auto;

    background-color: #fff;
  }

  &__header {
      position: relative;
      z-index: 2;

      display: flex;
      justify-content: space-between;
      padding: 18px 25px;

      background-color: #fafbfc;
      border-bottom: 1px solid #e7e8ec;
  }

  &__title {
    font-family: 'SFPro', Roboto, sans-serif;
    font-size: 15px;
  }
}

// headers //

.PanelHeader-left-in {
  display: flex;
  align-items: center;

  font-family: 'SfPro', sans-serif;
  letter-spacing: -0.08px;
  color: #707d8c;
}

.PanelHeader__content {
  cursor: default !important;
}

// modals //

.ModalRoot__mask {
  top: 55px;
}


iframe {
  position: fixed;

  width: 100%;
  height: calc(100vh - 36px);

  border: 0;
}

.cursor-default {
  cursor: default;
}
